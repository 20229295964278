import { Button, Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  useGetStockOrdersQuery,
  useGetSoldOrdersQuery,
  useGetSearchOrdersQuery,
} from "entities/orders/model/rtk-api";
import { getRoles } from "entities/viewer/model";
import { push } from "redux-first-history";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { Orders } from "entities/orders/ui/NewOrderList";
import { Link } from "react-router-dom";
import { getOrderList } from "entities/orders/model";
import { useGetLeadsQuery } from "entities/crm/leads/model/qapi";

export const StartPage = () => {
  const [srvSearch, setSrvSearch] = useState("");
  const [searchString, setSearchString] = useState("none");
 
  // const { data: stock } = useGetStockOrdersQuery(undefined);
  // const { data: sold } = useGetSoldOrdersQuery(undefined);
  const { data: searched } = useGetSearchOrdersQuery(searchString);
  const searchHandler = () => {
    setSearchString(srvSearch);
  };
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.viewer.user);
  const { data, refetch } = useGetLeadsQuery("")
  // const roles = useAppSelector(getRoles);
  // if (roles?.includes("stock_viewer")) {
  //   dispatch(push("/orders/stock"));
  // } else {
  //   if (roles?.includes("saled_viewer")) dispatch(push("/orders/saled"));
  // }
  if (user && user.start_page) dispatch(push(user.start_page));

  useEffect(() => {
    dispatch(getOrderList("in stock"));
    refetch();
  }, []);

  return (
    <>
      <Stack direction="row" spacing={1} marginTop={2} alignItems="center">
        <TextField
          size="small"
          value={srvSearch}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSrvSearch(event.target.value);
          }}
          onKeyDown={(e) => (e.keyCode === 13 ? searchHandler() : null)}
        />
        <ClearIcon
          sx={{ color: "grey", cursor: "pointer" }}
          onClick={() => setSrvSearch("")}
        />
        <Button variant="outlined" onClick={() => searchHandler()}>
          Cerca
        </Button>
        <Link to="/orders">
          ORDINI
        </Link>
      </Stack>
      {searched && <Orders orders={searched} />}
    </>
  );
};
